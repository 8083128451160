import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const StyledVideo = styled.video`
  margin-top: 1rem;
  max-width: 100%;

  @media (min-width: 576px) {
    max-width: 80%;
  }
`;

type VideoProps = {
  fileName: string;
};

export default function Video(props: VideoProps) {
  const { fileName } = props;

  const data = useStaticQuery(graphql`
    {
      allFile {
        edges {
          node {
            id
            name
            videoH264 {
              path
              absolutePath
              name
              ext
              formatName
              formatLongName
              startTime
              duration
              size
              bitRate
              width
              height
              aspectRatio
            }
            videoH265 {
              path
              absolutePath
              name
              ext
              formatName
              formatLongName
              startTime
              duration
              size
              bitRate
              width
              height
              aspectRatio
            }
            videoVP9 {
              path
              absolutePath
              name
              ext
              formatName
              formatLongName
              startTime
              duration
              size
              bitRate
              width
              height
              aspectRatio
            }
            videoScreenshots {
              id
              publicURL
            }
          }
        }
      }
    }
  `);

  const edge = data?.allFile?.edges?.find(
    (edge) => edge?.node.name === fileName
  );

  if (!edge?.node) {
    return null;
  }

  const { videoH264, videoH265, videoVP9, videoScreenshots } = edge.node;

  return (
    <StyledVideo controls poster={videoScreenshots[0].publicURL}>
      {videoH265 && (
        <source src={videoH265.path} type="video/mp4; codecs=hevc" />
      )}
      {videoVP9 && (
        <source src={videoVP9.path} type="video/webm; codecs=vp9,opus" />
      )}
      {videoH264 && (
        <source src={videoH264.path} type="video/mp4; codecs=avc1" />
      )}
    </StyledVideo>
  );
}
