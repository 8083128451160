import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/Layout';
import Video from '../components/Video';

const Tips = styled.div`
  margin-top: 2rem;
  margin-bottom: 50vh;
`;

export const Tip = styled.article`
  margin-bottom: 3.5rem;
  line-height: 1.5;
  text-align: justify;

  kbd {
    border: 1px solid #b4b4b4;
    border-radius: 3px;
    padding: 2px 4px;
    font-size: 0.85em;
    line-height: 1;
    font-weight: 700;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
      0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
    color: #333;
    background-color: #eee;
  }

  ul {
    list-style: disc;
    padding-inline-start: 15px;
  }

  blockquote {
    font-style: italic;
    margin-inline-start: 1em;
  }

  .gatsby-image-wrapper {
    display: block;
    margin: 0 auto;
    max-width: 100%;

    @media (min-width: 576px) {
      max-width: 80%;
    }
  }
`;

export const TipHeading = styled.div`
  margin-bottom: 1rem;
  text-align: left;

  h4 {
    display: inline;
    font-weight: bold;
  }

  time {
    color: #999;
  }

  h4,
  time {
    a,
    a:visited {
      color: #000;
      text-decoration: none;
    }

    a:active,
    a:hover {
      text-decoration: underline;
    }
  }
`;

export const shortcodes = { Video };

// https://gist.github.com/codeguy/6684588#gistcomment-3974852
export const slugify = (path: string) => {
  return path
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .trim()
    .replace(/\s+/g, '-');
};

export default function TILPage({ data }) {
  const { allMdx } = data;

  return (
    <Layout
      title="Today I Learned"
      subTitle="Some of the things I picked up along the way"
    >
      <Helmet>
        <title>Today I Learned | Steven Frostwick</title>
        <meta
          property="og:title"
          content="Today I Learned | Steven Frostwick"
        />
        <meta
          property="og:description"
          content="A log of small things I've learned along the way."
        />
      </Helmet>
      <Tips>
        {allMdx.nodes.map((node) => {
          const { body, frontmatter } = node;

          return (
            <Tip key={node.id} id={slugify(frontmatter.title)}>
              <TipHeading>
                <time dateTime={frontmatter.date}>
                  <a
                    href={`#${slugify(frontmatter.title)}`}
                    title={`Permalink to ${frontmatter.title}`}
                  >
                    {frontmatter.formattedDate}
                  </a>
                </time>{' '}
                <h4>
                  <a
                    href={`/today-i-learned/${slugify(frontmatter.title)}`}
                    title={`Permalink to ${frontmatter.title}`}
                  >
                    {frontmatter.title}
                  </a>
                </h4>
              </TipHeading>
              <MDXProvider components={shortcodes}>
                <MDXRenderer localImages={frontmatter.embeddedImagesLocal}>
                  {body}
                </MDXRenderer>
              </MDXProvider>
            </Tip>
          );
        })}
      </Tips>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        frontmatter {
          date
          formattedDate: date(formatString: "Do MMM yyyy")
          title
          embeddedImagesLocal {
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        body
        id
      }
    }
  }
`;
