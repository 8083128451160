import React from 'react';
import styled from 'styled-components';

import { slugify, TipHeading } from '../pages/today-i-learned';

const Title = styled.h5`
  margin-bottom: 1rem;
  font-weight: bold;
`;

const PostLink = styled.a`
  display: block;
  margin-bottom: 1rem;
  text-align: left;
  text-decoration: none;

  time {
    color: #666;
  }

  span {
    color: #000;
    text-decoration: underline;
  }
`;

export default function LatestTILList(props) {
  return (
    <div>
      <Title>Latest Today I Learned Posts</Title>
      {props.posts.map(({ frontmatter }) => (
        <PostLink
          href={`/today-i-learned/${slugify(frontmatter.title)}`}
          title={`Permalink to ${frontmatter.title}`}
        >
          <time dateTime={frontmatter.date}>{frontmatter.formattedDate}</time>{' '}
          <span>{frontmatter.title}</span>
        </PostLink>
      ))}
    </div>
  );
}
