import React from 'react';
import styled from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { shortcodes, Tip } from '../pages/today-i-learned';
import Layout from '../components/Layout';
import LatestTILList from '../components/LatestTILList';

const Time = styled.time`
  display: block;
  margin-top: 0.5rem;
  color: #999;
`;

export default function TILPost(props) {
  const { pageContext } = props;
  const { frontmatter, body, latest5Posts } = pageContext;

  return (
    <Layout
      title={frontmatter.title}
      optionalTitleChildren={
        <Time dateTime={frontmatter.date}>{frontmatter.formattedDate}</Time>
      }
    >
      <Tip>
        <MDXProvider components={shortcodes}>
          <MDXRenderer localImages={frontmatter.embeddedImagesLocal}>
            {body}
          </MDXRenderer>
        </MDXProvider>
      </Tip>
      <LatestTILList posts={latest5Posts} />
    </Layout>
  );
}
